import { __decorate } from "tslib";
import { NoCache } from "@/helpers/NoCache";
import { core } from "@/store/modules/core";
import { indicatorCharts } from "@/store/modules/indicator-chart";
import { indicatorChartReferencesModule } from "@/store/modules/indicator-chart-references";
import { unitReferencesModule } from "@/store/modules/unit-references";
import { v4 as uuid } from "uuid";
import { Component, Vue, Watch } from "vue-property-decorator";
import AppBarChart from "./AppBarChart.vue";
import EntryEditModal from "./EntryEditModal.vue";
import AppBtn from "./shared/ui/AppBtn.vue";
import AppSelect from "./shared/ui/AppSelect.vue";
import AppTextPlaceholder from "./shared/ui/AppTextPlaceholder.vue";
let Entry = class Entry extends Vue {
    constructor() {
        super(...arguments);
        this.showEditModal = false;
        this.editedIndicatorChart = null;
        this.chartKey = 0;
    }
    get placeholderText() {
        const unit = unitReferencesModule.unitReferences?.find((u) => u.id === core.unitId)?.name;
        return `Aucun graphique pour ${unit} en ${core.year}`;
    }
    get indicatorCharts() {
        return indicatorCharts.indicatorCharts;
    }
    get loadChartParams() {
        return core.unitId && core.year && core.indicatorId && new Date();
    }
    get unitId() {
        return core.unitId;
    }
    set unitId(unitId) {
        core.setUnitId(unitId);
    }
    get unitItems() {
        return unitReferencesModule.unitReferences?.map((unit) => ({
            label: `${unit.company}/${unit.name}`,
            value: unit.id,
            group: unit.group,
        }));
    }
    mounted() {
        this.$events.$emit("menu-change", {
            menu: "Indicateurs",
            subMenu: "Saisie",
        });
    }
    onLoadChartParamsChange() {
        indicatorCharts.loadIndicatorCharts();
    }
    createIndicatorCharts() {
        const charts = indicatorChartReferencesModule.indicatorChartReferences
            ?.filter((i) => i.indicatorId === core.indicatorId)
            ?.map((indicatorChartReference) => {
            const indicatorChart = {
                id: uuid(),
                chartId: indicatorChartReference.id,
                year: core.year,
                unitId: core.unitId,
                indicatorId: core.indicatorId,
                entries: indicatorChartReference.labels.map((l) => ({
                    labelId: l.id,
                    value: 0,
                })),
            };
            return indicatorChart;
        });
        for (const indicatorChart of charts) {
            indicatorCharts.upsertIndicatorChart(indicatorChart);
        }
    }
    editIndicatorChart(indicatorChart) {
        this.editedIndicatorChart = indicatorChart;
        this.showEditModal = true;
    }
    /**
     * Clean and force refresh of charts to reload showGroupAverage
     */
    onEditConfirmed(confirmed) {
        this.editedIndicatorChart = null;
        if (confirmed)
            this.chartKey++;
    }
};
__decorate([
    NoCache()
], Entry.prototype, "indicatorCharts", null);
__decorate([
    Watch("loadChartParams", { immediate: true })
], Entry.prototype, "onLoadChartParamsChange", null);
Entry = __decorate([
    Component({
        components: {
            AppBtn,
            AppTextPlaceholder,
            AppBarChart,
            EntryEditModal,
            AppSelect,
        },
    })
], Entry);
export default Entry;
