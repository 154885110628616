import { __decorate } from "tslib";
import { auth } from "@/store/modules/auth";
import { indicatorCharts } from "@/store/modules/indicator-chart";
import { indicatorChartReferencesModule } from "@/store/modules/indicator-chart-references";
import { mixins } from "vue-class-component";
import { Component, Prop, VModel, Watch } from "vue-property-decorator";
import AppRefNamer from "./shared/AppRefNamer";
import AppModal from "./shared/ui/AppModal.vue";
import AppTextField from "./shared/ui/AppTextField.vue";
let EntryEditModal = class EntryEditModal extends mixins(AppRefNamer) {
    constructor() {
        super(...arguments);
        this.entries = [];
    }
    get indicatorChartReference() {
        return indicatorChartReferencesModule.indicatorChartReferences?.find((i) => i.id === this.editedItem.chartId);
    }
    onEditedItemChange() {
        if (this.editedItem)
            this.entries = [
                ...this.indicatorChartReference.labels?.map((l) => ({
                    labelId: l.id,
                    value: this.editedItem.entries?.find((e) => e.labelId === l.id)?.value ??
                        null,
                })),
            ];
    }
    async onConfirm(confirmed) {
        if (confirmed) {
            await indicatorCharts.upsertIndicatorChart({
                ...this.editedItem,
                entries: [...this.entries],
                updatedAt: new Date(),
                updatedBy: auth.user.email,
            });
        }
        this.$emit("confirmed", confirmed);
    }
};
__decorate([
    VModel({ default: false })
], EntryEditModal.prototype, "showModal", void 0);
__decorate([
    Prop()
], EntryEditModal.prototype, "editedItem", void 0);
__decorate([
    Watch("editedItem", { immediate: true })
], EntryEditModal.prototype, "onEditedItemChange", null);
EntryEditModal = __decorate([
    Component({
        components: {
            AppModal,
            AppTextField,
        },
    })
], EntryEditModal);
export default EntryEditModal;
