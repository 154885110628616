import { __decorate } from "tslib";
import indicatorChartsAPI from "@/api/indicatorCharts";
import { mutateInArray } from "@/helpers/array";
import { handleError } from "@/helpers/error";
import store from "..";
import { getModuleConfig } from "../helpers/get-module-config";
import { Action, Module, Mutation, VuexModule, } from "../helpers/vuex-class-modules";
import { core } from "./core";
let IndicatorChartModule = class IndicatorChartModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.indicatorCharts = null;
    }
    setIndicatorCharts(indicatorCharts) {
        this.indicatorCharts = indicatorCharts;
    }
    setIndicatorChart(indicatorChart) {
        this.indicatorCharts = mutateInArray(this.indicatorCharts, indicatorChart, (c) => c.id, true);
    }
    unsetIndicatorChart(indicatorChartId) {
        this.indicatorCharts = this.indicatorCharts.filter((c) => c.id !== indicatorChartId);
    }
    async loadIndicatorCharts() {
        if (!core.indicatorId || !core.year || !core.unitId)
            return;
        try {
            const indicatorCharts = await indicatorChartsAPI.load(core.year, core.indicatorId, core.unitId);
            this.setIndicatorCharts(indicatorCharts);
        }
        catch (err) {
            handleError(err);
        }
    }
    async upsertIndicatorChart(indicatorChart) {
        const previousIndicatorChart = this.indicatorCharts?.find((c) => c.id === indicatorChart.id);
        try {
            this.setIndicatorChart(indicatorChart);
            await indicatorChartsAPI.upsert(indicatorChart);
        }
        catch (err) {
            this.unsetIndicatorChart(indicatorChart.id);
            if (previousIndicatorChart)
                this.setIndicatorChart(previousIndicatorChart);
            handleError(err);
        }
    }
    async deleteIndicatorChart(indicatorChartId) {
        const previousIndicatorChart = this.indicatorCharts.find((c) => c.id === indicatorChartId);
        try {
            this.unsetIndicatorChart(indicatorChartId);
            await indicatorChartsAPI.delete(indicatorChartId);
        }
        catch (err) {
            if (previousIndicatorChart)
                this.setIndicatorChart(previousIndicatorChart);
            handleError(err);
        }
    }
};
__decorate([
    Mutation
], IndicatorChartModule.prototype, "setIndicatorCharts", null);
__decorate([
    Mutation
], IndicatorChartModule.prototype, "setIndicatorChart", null);
__decorate([
    Mutation
], IndicatorChartModule.prototype, "unsetIndicatorChart", null);
__decorate([
    Action
], IndicatorChartModule.prototype, "loadIndicatorCharts", null);
__decorate([
    Action
], IndicatorChartModule.prototype, "upsertIndicatorChart", null);
__decorate([
    Action
], IndicatorChartModule.prototype, "deleteIndicatorChart", null);
IndicatorChartModule = __decorate([
    Module(getModuleConfig())
], IndicatorChartModule);
export { IndicatorChartModule };
export const indicatorCharts = new IndicatorChartModule({
    store,
    name: "indicatorCharts",
});
